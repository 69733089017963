import { Button, Input, Form } from "antd"
import TopbarPure from "../../components/organisms/topbar/topbarPure"
import React, { useRef, useState } from "react"
import "./style.css"
import { changePasswordDirect } from "../../services/user"
import { openCustomNotificationWithIcon } from "../../common/notifycation"
import { navigate } from "gatsby"
function index() {
  const ref = useRef()

  const [toggleMessage, setToggleMessage] = useState(false)

  const [form] = Form.useForm()
  const handleSubmit = async (values) => {
    if (values.old_password === values.new_password) {
      openCustomNotificationWithIcon(
        "error",
        "エラーが発生しました。",
        "古いパスワードを重複に入力しました。新しいパスワードを入力してください。"
      )
    } else {
      const auth_data = {
        new_password: values.new_password,
        password: values.old_password,
      }
      const result = await changePasswordDirect(auth_data)

      if (result.response.status === 200) {
        setToggleMessage(false)
        openCustomNotificationWithIcon("success", "成功", "保存しました。")
        navigate("/home")
      } else {
        setToggleMessage(true)
      }
    }
  }
  return (
    <div>
      <TopbarPure />
      <div className="forgot_password_body">
        <div className="card_input_info">
          <div className="label_header">
            <div className="title_header">パスワードの更新</div>
            <Form onFinish={handleSubmit} form={form} ref={ref}>
              <div className="divInput">
                <div>以前のパスワード</div>
                <Form.Item
                  name="old_password"
                  rules={[
                    {
                      required: true,
                      message: `パスワードを入力してください。`,
                    },
                    {
                      min: 8,
                      message: `パスワードは8文字以上である必要があります。`,
                    },
                  ]}
                >
                  <Input.Password placeholder="古いパスワード" />
                </Form.Item>
                {toggleMessage && (
                  <span className="message-error">
                    パスワードが正しくありません。
                  </span>
                )}
              </div>
              <div className="divInput">
                <div>新しいパスワード</div>
                <Form.Item
                  name="new_password"
                  rules={[
                    {
                      required: true,
                      message: `パスワードを入力してください。`,
                    },
                    {
                      min: 8,
                      message: `パスワードは8文字以上である必要があります。`,
                    },
                  ]}
                >
                  <Input.Password placeholder="新しいパスワード" />
                </Form.Item>
              </div>
              <div className="divInput">
                <div>新しいパスワードの確認</div>
                <Form.Item
                  name="confirm"
                  dependencies={["new_password"]}
                  rules={[
                    {
                      required: true,
                      message: `パスワードを入力してください。`,
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("new_password") === value) {
                          return Promise.resolve()
                        }
                        return Promise.reject(
                          new Error("新しいパスワードの確認 が一致しません。")
                        )
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder="パスワードの確認入力" />
                </Form.Item>
              </div>
              <div className="flex justify-center" style={{ marginBottom: 80 }}>
                <Button
                  type="default"
                  style={{ backgroundColor: "#EC4D43" }}
                  className="button_submit"
                  size="large"
                  htmlType="submit"
                >
                  パスワードを更新する
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <div
        className="flex text-grey-0 inter-base-regular justify-center"
        style={{ marginTop: "415px" }}
      >
        <span>&#183;</span>{" "}
        <a
          style={{ color: "#888888", textDecoration: "none" }}
          href="mailto:hello@mrfood.com"
        >
          Copyright © ミスターフード All Rights Reserved.
        </a>
      </div>
    </div>
  )
}

export default index
